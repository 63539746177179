import React from "react";

export default class Projects extends React.Component {
  render() {
    return (
      <>
        <h1>Projects</h1>
      </>
    );
  }
}
