import React from "react";

export default class ContectMe extends React.Component {
  render() {
    return (
      <>
        <h1>Yell really really loud and I'll hear.</h1>
      </>
    );
  }
}
